html {
  font-size: 15px;
}
@media (min-width: 1600px) {
  html {
    font-size: 18px;
  }
}
body {
  background-color: $secondary;
  font-family: 'Source Sans Pro', sans-serif;
}

.custom-file-label::after {
  font-family: "Font Awesome 5 Free";
  content: "\f07c" !important;
}

@media (max-width: 767px) {
  .hide-xs {
    display: none;
  }
  .form-min-w100px-xs {
    min-width: 100px;
  }
  .form-min-w150px-xs {
    min-width: 150px;
  }
}
@media (min-width: 992px) {
  .hide-md {
    display: none;
  }
}
