$blue: #5e72e4;
$indigo: #5603ad;
$purple: #8965e0;
$pink: #f3a4b5;
$red: #f5365c;
$orange: #fb6340;
$yellow: #ffd600;
$green: #2dce89;
$teal: #11cdef;
$cyan: #2bffc6;
$white: #fff;
$gray: #8898aa;
$gray-dark: #32325d;
$light: #ced4da;
$lighter: #e9ecef;
$primary: $blue;
$secondary: #f4f5f7;
$success: $green;
$info: $teal;
$warning: $orange;
$danger: $red;
$dark: #212529;
$default: #172b4d;
$white: #fff;
$neutral: #fff;
$darker: #000;

$brand: () !default;
$brand: map-merge((
  "primary": $purple,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
), $brand);