.main-section {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  box-shadow: 0 0 50px rgba(0,0,0,.15);
  margin-top: -4rem;
  background-color: $white;
  position: relative;
  padding: 2rem;
}

.section {
  padding: 1.5rem 0;
}

.head-section {
  text-align: center;
  margin-bottom: 3rem;
}
.title-section {
  font-weight: 700;
  text-transform: uppercase;
}