.navbar {
  transition: all .35s ease-in;
  .navbar-nav {
    .nav-link {
      font-size: 1.05rem;
      padding-left: 1rem;
      padding-right: 1rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &.affix {
    box-shadow: 0 .5rem 1rem -.5rem rgba(0,0,0,.25);
  }
}

.navbar-brand {
  img {
    height: 50px;
  }
}

.navbar-toggler {
  padding: .25rem;
  border: 0;
  &:focus {
    outline: none;
  }
}

@media (max-width: 767px) {
  .navbar-brand {
    img {
      height: 45px;
    }
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,.25);
    }
    .navbar-nav {
      position: fixed;
      width: 240px;
      top: 0;
      right: -240px;
      bottom: 0;
      padding: 2rem 0;
      transition: right .35s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &.open {
      .navbar-nav {
        right: 0;
      }
    }
  }
  .navbar {
    &.navbar-dark {
      .navbar-collapse {
        .navbar-nav {
          background-color: $primary;
        }
        &.open {
          .navbar-nav {
            box-shadow: -.5rem 0 1rem rgba($primary, .25);
          }
        }
      }
    }
    &.navbar-light {
      .navbar-collapse {
        .navbar-nav {
          background-color: $white;
        }
        &.open {
          .navbar-nav {
            box-shadow: -.5rem 0 1rem rgba($dark, .25);
          }
        }
      }
    }
  }
}